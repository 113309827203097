import React, { useEffect, useState } from 'react'
import {
  Headline,
  transformRegionToMap,
  DynamicTag,
  RecommendationCarousel,
  CustomLinkComponent,
  Spinner
} from 'msp-components'
import {
  useSearch,
  SearchHitItem,
  useCommerceAPI,
  IProductData
} from 'msp-integrations'
import ProductTile from '@/components/molecules/ProductTile/ProductTile'
import * as T from './CustomRecommendation.types'
import * as S from './CustomRecommendation.styles'

/**
 * Build Product Tile component
 * @param {any} product Product
 * @returns {JSX.Element} Product Tile component
 */
function buildProductTile(product: IProductData): JSX.Element {
  return <ProductTile key={`product-tile-${product.id}`} data={product} />
}

const CustomRecommendation = ({
  data,
  custom,
  regions
}: T.CustomRecommendationProps) => {
  const api = useCommerceAPI()
  const areas = transformRegionToMap(regions)
  const [validProducts, setValidProducts] = useState([])
  const productIds =
    areas
      ?.get('products')
      ?.components?.map((item: any) => item.data?.productId) || []
  const searchParams = {
    refine: [`ID=${productIds.join('|')}`]
  }
  const [isLoading, setIsLoading] = useState(true)
  const { search, isLoading: isSearchLoading } = useSearch(searchParams)
  const { headline, actionLinkText } = data
  const { actionLink } = custom

  useEffect(() => {
    if (!isSearchLoading) {
      const singleProductPerMaster = {}
      const filteredSearchHits =
        search?.hits?.filter((item: SearchHitItem) => {
          if (item.c_masterId && singleProductPerMaster[item.c_masterId]) {
            return false
          }

          singleProductPerMaster[item.c_masterId || 'default'] = true

          return (
            productIds.some((id: string) => id === item.productId) ||
            item.c_masterId
          )
        }) || []

      const validatedProductIds = filteredSearchHits.map(
        ({ productId }: SearchHitItem) => productId
      )

      api.shopperProducts
        .getProducts({
          parameters: {
            ids: validatedProductIds.join(','),
            allImages: true,
            perPricebook: true
          }
        })
        .then(({ data: products }: any) => {
          setValidProducts(products)
          setIsLoading(false)
        })
    }
  }, [isSearchLoading])

  return (
    <div className={S.CustomRecommendationWrapper}>
      <div className={S.CustomRecommendationHeadWrapper}>
        <Headline tag='h2' className='headline-h5 text-black'>
          {headline}
        </Headline>
        <CustomLinkComponent
          href={actionLink}
          inline={true}
          className={S.CustomRecommendationLinkWrapper}
          uppercase={false}
        >
          {actionLinkText}
        </CustomLinkComponent>
      </div>
      <div className={S.CustomRecommendationCarouselWrapper}>
        {!isLoading && validProducts && validProducts.length > 0 ? (
          <RecommendationCarousel
            productTiles={validProducts.map(buildProductTile)}
          />
        ) : (
          <div className={S.CustomRecommendationLoadingWrapper}>
            <div className={S.CustomRecommendationLoadingTile}>
              <Spinner />
            </div>
            <div className={S.CustomRecommendationLoadingTile}>
              <Spinner />
            </div>
            <div className={S.CustomRecommendationLoadingTile}>
              <Spinner />
            </div>
            <div className={S.CustomRecommendationLoadingTile}>
              <Spinner />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomRecommendation
